.results-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.results {
  margin: auto;
  width: 80%;
  max-width: 1000px;
  padding: 0 1rem;
}

.results table div {
  margin-bottom: 0.5rem;
}

.results table div:last-child {
  margin-bottom: 0;
}

.feedback {
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
}

.feedback a {
  margin: 0 0.5rem;
}
